/* eslint-disable max-len */
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { forEach, omit } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Joi from '@hapi/joi';
// import * as csv from 'csvtojson';
import { csv2json } from 'json-2-csv';
// import moment from 'moment';
import { withStyles } from '@mui/styles';

import { apiFetch } from '../lib/fetch';
import { colors, Button } from '../lib/styles';

const styles = {
  btnStyle: {
    backgroundColor: 'rgba(32, 123, 204)',
    border: '1px solid blue',
    color: 'white',
    width: '175px',
  },
  errorMsg: {
    color: 'red',
  },
  inviteText: {
    cursor: 'pointer',
    textAlign: 'center',
  },
  populatedECAList: {
    minHeight: '100px',
    maxHeight: '300px',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    overflowY: 'scroll',
    paddingInlineStart: '25px',
    marginBottom: '20px',
  },
  unpopulatedECAlist: {
    height: '300px',
    maxHeight: '300px',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
  },
  subHeader: {
    textAlign: 'center',
    color: colors.black,
  },
  forms: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const HiddenContent = (props) => {
  if (props.hidden) {
    return null;
  }
  return props.children;
};

// const defaultError = 'An error occurred submitting this list, please review your import file carefully to ensure and try again. If this problem persists contact support.';

const PreImportRow = (props) => {
  const { error, id } = props;
  return (
    <li style={props.isValid ? {} : { color: 'red' }}>
      <Typography variant="subtitle1">
        <span style={{ fontWeight: 'bold' }}>{props.carrier}</span>,
        {props.agent_full_name},
        <span style={{ fontWeight: 'bold' }}>{props.policy_number}</span>,
        {props.product_name || ''},
        {props.policy_type || ''},
        {props.statement_date},
        {props.premium_amount},
        <span style={{ fontWeight: 'bold' }}>${props.commission}</span>
        {props.squashedVals ? (
          <span style={{ fontWeight: 'bold', color: 'blue', marginLeft: '10px' }}>({props.squashedVals.join(', ')})</span>
        ) : ''}
        {props.ecas ? (
          <span style={{ fontWeight: 'bold', color: 'blue', marginLeft: '10px' }}>({props.ecas.map((e) => `$${e.commission}`).join(' + ')})</span>
        ) : ''}
      </Typography>
      <span>
        {error && error.code === 422 ? (
          <span style={{ color: 'red' }}>
            {error.message}
            {error.bsf_id ? (
              <Link to={`/bsfs/${error.bsf_id}`} style={{ color: 'blue', marginLeft: '10px' }} target="_blank">
                BSF id {error.bsf_id}
              </Link>
            ) : ''}
          </span>
        ) : ''}
        {error && error.code !== 422 ? (
          <span style={{ color: 'red' }}>
            {error.message}
          </span>
        ) : ''}
        {id ? (
          <span style={{ color: 'green' }}>
            <CheckIcon />
          </span>
        ) : ''}
      </span>
    </li>
  );
};

const validationSchema = Joi.object({
  policy_number: Joi.string().required(),
  premium_amount: Joi.number().precision(2).required(),
  // agent_amount: Joi.number().precision(2).required(),
  // basis: Joi.number().precision(2).required(),
  agent_name: Joi.string(),
  // stronghold_amount: Joi.number().precision(2).required(),
  sh_portion: Joi.number().precision(2).required(),
  commission: Joi.number().precision(2).required(),
  // result: Joi.object(),
  renewal: Joi.boolean().required(),
});

const ImportECA = () => {
  const [allValid, setAllValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [imports, setImports] = useState([]);
  const [submissionError, setSubmissionError] = useState('');
  const [validationError, setValidationError] = useState('');
  const [forceUpload, setForceUpload] = useState(false);
  const [file, setFile] = useState(null);
  const history = useHistory();

  const handleAddFileContentsToState = async (rawContent) => {
    try {
      const data = await csv2json(rawContent, { delimiter: { eol: '\r\n' } });
      const cleanedHeaders = data.map((rec) => {
        const newRec = {};
        const keys = Object.keys(rec);
        keys.forEach((k) => {
          if (k && k.trim) {
            const newKey = k.trim().toLocaleLowerCase();
            if (newKey) {
              newRec[k.trim().toLocaleLowerCase()] = rec[k];
            }
          }
        });
        return newRec;
      });

      const filledRecords = [];
      cleanedHeaders.forEach((ch) => {
        if (ch.contract_id && ch.agent_amount) {
          filledRecords.push(ch);
        }
      });

      const cleanedRecords = filledRecords.map(({
        contract_id, agent_amount, stronghold_amount, business_type, agent_name, basis,
      }) => {
        let aa = String(agent_amount).trim().replaceAll(',', '').replaceAll('$', '').replaceAll(' ', '');
        if (aa.startsWith('(')) {
          aa = aa.replaceAll('(', '');
          aa = aa.replaceAll(')', '');
          if (!aa.startsWith('-')) {
            aa = '-' + aa;
          }
        }

        let sa = String(stronghold_amount).trim().replaceAll(',', '').replaceAll('$', '').replaceAll(' ', '');
        if (sa.startsWith('(')) {
          sa = sa.replaceAll('(', '');
          sa = sa.replaceAll(')', '');
          if (!sa.startsWith('-')) {
            sa = '-' + sa;
          }
        }

        let ba = String(basis).trim().replaceAll(',', '').replaceAll('$', '').replaceAll(' ', '');
        if (ba.startsWith('(')) {
          ba = ba.replaceAll('(', '');
          ba = ba.replaceAll(')', '');
          if (!ba.startsWith('-')) {
            ba = '-' + ba;
          }
        }

        const cr = {
          carrier: 'HEDGEHOG',
          policy_number: String(contract_id).trim(),
          // policy_type: String(policy_type).trim(),
          // statement_date: moment(String(statement_date).trim()).toISOString(),
          premium_amount: Number(ba),
          commission: Number(aa),
          sh_portion: Number(sa),
          renewal: business_type ? business_type.toLowerCase() === 'renewal' : false,
          isValid: true,
        };
        if (agent_name) {
          cr.agent_full_name = String(agent_name).trim();
        }
        return cr;
      });

      cleanedRecords.forEach((cr) => {
        const validationResult = validationSchema.validate(cr, { allowUnknown: true });
        if (validationResult.error) {
          setValidationError(validationResult.error);
          cr.isValid = false;
          setAllValid(false);
        }
      });
      setImports(cleanedRecords);

      setSubmitting(true);
      const preTestedRecords = await apiFetch('/eca/test-batch', {
        method: 'POST',
        body: {
          ecas: cleanedRecords,
        },
      });
      let hasErrors = false;
      preTestedRecords.forEach((ptr) => {
        if (ptr.error) {
          ptr.isValid = false;
          hasErrors = true;
        }
      });
      setSubmitting(false);
      if (hasErrors) {
        setForceUpload(true);
      }
      setImports(preTestedRecords);
    } catch (e) {
      setValidationError(e);
    }
  };
  const onChangeFile = (e) => {
    e.persist();
    const { files } = e.target;
    // Forces onChange to get called again if same file is resubmitted
    if (files && files.length) {
      setAllValid(true);
      forEach(files, (f) => {
        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        setFile(f);
        reader.onload = (readerEvent) => handleAddFileContentsToState(readerEvent.target.result);
        reader.readAsText(f);
      });
    }
  };

  const handleSubmitECAs = async () => {
    setSubmissionError('');
    setSubmitting(true);
    for (const imp of imports) {
      delete imp.result;
      delete imp.error;
    }
    const options = {
      method: 'POST',
      body: {
        ecas: imports.map((i) => omit(i, 'ecas')),
      },
    };
    try {
      const batchResult = await apiFetch('/eca/force-batch', options);
      if (batchResult.errors) {
        batchResult.ecas.forEach((eca) => {
          if (eca.error) {
            setSubmissionError(eca.error.message);
            setForceUpload(true);
            eca.isValid = false;
          } else {
            eca.isValid = true;
          }
        });
        setImports(batchResult.ecas);
        setSubmissionError(false);
        return;
      }
      const formData = new FormData();
      formData.append('content_type', file.type);
      formData.append('file', file);
      formData.append('name', file.name);
      apiFetch.post('/comp_upload_files/files', formData)
        .then(() => {
          history.go('/eca');
        })
        .catch((err) => {
          /* eslint-disable-next-line no-console */
          console.error('err', err);
          history.go('/eca');
        });
    } catch (e) {
      setSubmissionError(e.message);
    }
    setSubmitting(false);
    // history.go(0);
  };
  const handleClearPreviousFile = (e) => {
    e.target.value = null;
  };
  const ecaRows = imports.map((i, idx) => {
    return (
      <PreImportRow
        key={i.policy_number + Math.random()}
        {...i}
        number={idx + 1}
      />
    );
  });

  return (
    <>
      <ol>
        <li>
          <Typography variant="subtitle1">Once the HedgeHog ECAs have been reviewed click the &quot;Submit ECAs&quot; button below.</Typography>
        </li>
      </ol>
      <Button
        color="primary"
        component="label"
      >
        Upload File
        <input
          onClick={handleClearPreviousFile}
          onChange={onChangeFile}
          type="file"
          accept=".csv"
          style={{ display: 'none' }}
        />
      </Button>
      <Typography variant="h6" style={styles.subHeader}>Hedgehog ECAs to Insert</Typography>
      <HiddenContent hidden={!validationError}>
        <span className={styles.errorMsg}>{validationError.message}</span>
      </HiddenContent>
      <ol className={ecaRows.length ? styles.populatedECAList : styles.unpopulatedECAlist}>
        {ecaRows}
      </ol>
      <div>
        <Button
          gold
          disabled={!allValid && !forceUpload}
          onClick={handleSubmitECAs}
        >
          {forceUpload ? 'Force ECAs to upload' : 'Submit ECAs'}
        </Button>
      </div>
      <HiddenContent hidden={!submissionError || submitting}>
        <span className={styles.errorMsg}>{submissionError}</span>
      </HiddenContent>
      <div style={{ marginBottom: '40px' }}/>
    </>
  );
};

export default withStyles(styles)(ImportECA);
