import React, { useState } from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '../lib/common';
import DownloadButton from '../components/download-csv';
import Layout from '../components/layout';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  {
    field: 'to_address',
    headerName: 'To',
    align: 'left',
    width: 250,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    align: 'left',
    width: 300,
  },
  {
    field: 'html',
    headerName: 'Content',
    align: 'left',
    width: 300,
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'left',
    width: 200,
  },
  {
    field: 'error_msg',
    headerName: 'Error',
    align: 'left',
    width: 200,
  },
  {
    field: 'created_at',
    headerName: 'Send Date',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
    },
  },
];

const EmailLog = () => {
  const classes = useStyles();
  const params = useParams();
  const { email } = params;
  const [err] = useState(null);

  let url = '/email_log?orderBy=created_at&limit=5000';
  if (email) {
    url = `/email_log?orderBy=created_at&limit=5000&to_address=${email}`;
  }

  const { data: bsf, loading: bsfLoading } = useSWR(url);

  return (
    <Layout headerTitle={`Email Log${email ? ` for ${email}` : ''}`} >
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        {bsf && bsf.length ? (<DownloadButton rows={bsf} name="feedback"/>) : ''}
        <Paper>
          {!bsfLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={bsf || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
      <Button sx={{ margin: '10px' }} href="/errors">
        Error Log
      </Button>
      <Button sx={{ margin: '10px' }} href="/docusign">
        Docusign Log
      </Button>
      <Button sx={{ margin: '10px' }} href="/pending_documents">
        Pending Documents
      </Button>
    </Layout>
  );
};

export default EmailLog;
