import React, { useState } from 'react';

import useSWR from 'swr';

import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';

import { colors } from '../lib/styles';
import DownloadButton from '../components/download-csv';
import Layout from '../components/layout';
import config from '../config';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
  w200: {
    width: '200px',
    margin: '10px',
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'context',
    headerName: 'Context',
    align: 'left',
    width: 200,
  },
  {
    field: 'message',
    headerName: 'Message',
    align: 'left',
    width: 250,
  },
  {
    field: 'code',
    headerName: 'Code',
    align: 'right',
    width: 150,
  },
  {
    field: 'stack',
    headerName: 'Stack',
    align: 'left',
    width: 250,
  },
  {
    field: 'json',
    headerName: 'Details',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return (
        <a
          href={`${config.API_URL}/error_log/${params.row.id}`}
          target="_blank"
          rel="noreferrer"
        >
          details
        </a>
      );
    },
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
    },
  },
];

const ErrorLog = () => {
  const classes = useStyles();
  const [err] = useState(null);
  const params = useParams();
  const { userId } = params;
  let url = '/error_log?orderBy=created_at&limit=5000';
  if (userId) {
    url = `${url}&agent=${userId}`;
  }
  const { data: logs, loading: logsLoading } = useSWR(url);

  return (
    <Layout headerTitle="Error Log">
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        {logs && logs.length ? (<DownloadButton rows={logs} name="error_log"/>) : ''}
        <Paper>
          {!logsLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={logs || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default ErrorLog;
