import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import { colors } from '../lib/styles';
import ImportBiCarrierContent from './import-eca-bi-content';

const styles = {
  headerTitle: {
    textAlign: 'center',
    color: colors.black,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px auto',
    minHeight: '250px',
    padding: '30px',
  },
};

const EcaDialog = (props) => {
  const {
    open,
    onClose,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        onBackdropClick={onClose}
        maxWidth="lg"
      >
        <div style={styles.form}>
          <Typography variant="h4" style={styles.headerTitle}>Instructions</Typography>
          <ImportBiCarrierContent />
        </div>
      </Dialog>
    </div>
  );
};

EcaDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EcaDialog;
