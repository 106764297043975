/* eslint-disable max-len */
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { forEach, omit } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Joi from '@hapi/joi';
// import * as csv from 'csvtojson';
import { csv2json } from 'json-2-csv';
import moment from 'moment';
import { withStyles } from '@mui/styles';

import { apiFetch } from '../lib/fetch';
import { colors, Button } from '../lib/styles';

const styles = {
  btnStyle: {
    backgroundColor: 'rgba(32, 123, 204)',
    border: '1px solid blue',
    color: 'white',
    width: '175px',
  },
  errorMsg: {
    color: 'red',
  },
  inviteText: {
    cursor: 'pointer',
    textAlign: 'center',
  },
  populatedECAList: {
    minHeight: '100px',
    maxHeight: '300px',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    overflowY: 'scroll',
    paddingInlineStart: '25px',
    marginBottom: '20px',
  },
  unpopulatedECAlist: {
    height: '300px',
    maxHeight: '300px',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
  },
  subHeader: {
    textAlign: 'center',
    color: colors.black,
  },
  forms: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const HiddenContent = (props) => {
  if (props.hidden) {
    return null;
  }
  return props.children;
};

// const defaultError = 'An error occurred submitting this list, please review your import file carefully to ensure and try again. If this problem persists contact support.';

const PreImportRow = (props) => {
  const { error, id } = props;
  return (
    <li style={props.isValid ? {} : { color: 'red' }}>
      <Typography variant="subtitle1">
        <span style={{ fontWeight: 'bold' }}>{props.carrier}</span>,
        {props.agent_full_name},
        <span style={{ fontWeight: 'bold' }}>{props.policy_number}</span>,
        {props.product_name || ''},
        {props.policy_type || ''},
        {props.statement_date},
        {props.premium_amount},
        <span style={{ fontWeight: 'bold' }}>${props.commission}</span>
        {props.squashedVals ? (
          <span style={{ fontWeight: 'bold', color: 'blue', marginLeft: '10px' }}>({props.squashedVals.join(', ')})</span>
        ) : ''}
        {props.ecas ? (
          <span style={{ fontWeight: 'bold', color: 'blue', marginLeft: '10px' }}>({props.ecas.map((e) => `$${e.commission}`).join(' + ')})</span>
        ) : ''}
      </Typography>
      <span>
        {error && error.code === 422 ? (
          <span style={{ color: 'red' }}>
            {error.message}
            {error.bsf_id ? (
              <Link to={`/bsfs/${error.bsf_id}`} style={{ color: 'blue', marginLeft: '10px' }} target="_blank">
                BSF id {error.bsf_id}
              </Link>
            ) : ''}
          </span>
        ) : ''}
        {error && error.code !== 422 ? (
          <span style={{ color: 'red' }}>
            {error.message}
          </span>
        ) : ''}
        {id ? (
          <span style={{ color: 'green' }}>
            <CheckIcon />
          </span>
        ) : ''}
      </span>
    </li>
  );
};

const validationSchema = Joi.object({
  // affiliate: Joi.string().required(),
  carrier: Joi.string().required(),
  // agent_full_name: Joi.string().required(),
  // client_name: Joi.string().required(),
  // client_dob: Joi.date(),
  // client_ssn: Joi.number().integer(),
  policy_number: Joi.string().required(),
  // product_name: Joi.string().required(),
  policy_type: Joi.string().required(),
  statement_date: Joi.date().required(),
  premium_amount: Joi.number().precision(2).required(),
  commission: Joi.number().precision(2).required(),
  result: Joi.object(),
});

const ImportECA = () => {
  const [allValid, setAllValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [imports, setImports] = useState([]);
  const [submissionError, setSubmissionError] = useState('');
  const [validationError, setValidationError] = useState('');
  const [forceUpload, setForceUpload] = useState(false);
  const history = useHistory();
  const [file, setFile] = useState(null);

  const handleAddFileContentsToState = async (fileContents) => {
    try {
      const data = await csv2json(fileContents, { delimiter: { eol: '\r\n' } });
      const cleanedHeaders = data.map((rec) => {
        const newRec = {};
        const keys = Object.keys(rec);
        keys.forEach((k) => {
          if (k && k.trim) {
            const newKey = k.trim().toLocaleLowerCase();
            if (newKey) {
              newRec[k.trim().toLocaleLowerCase()] = rec[k];
            }
          }
        });
        return newRec;
      });

      const filledRecords = [];
      cleanedHeaders.forEach((ch) => {
        if (ch.policy_number && ch.carrier) {
          filledRecords.push(ch);
        }
      });

      const cleanedRecords = filledRecords.map(({
        affiliate, carrier, agent_full_name, client_name, client_dob, client_ssn, policy_number, product_name, policy_type, statement_date, premium_amount, commission,
      }) => {
        let com = String(commission).trim().replaceAll(',', '').replaceAll('$', '').replaceAll(' ', '');
        if (com.startsWith('(')) {
          com = com.replaceAll('(', '');
          com = com.replaceAll(')', '');
          if (!com.startsWith('-')) {
            com = '-' + com;
          }
        }

        let prem = String(premium_amount).trim().replaceAll(',', '').replaceAll('$', '').replaceAll(' ', '');
        if (prem.startsWith('(')) {
          prem = prem.replaceAll('(', '');
          prem = prem.replaceAll(')', '');
          if (!prem.startsWith('-')) {
            prem = '-' + prem;
          }
        }

        const cr = {
          carrier: String(carrier).trim().toUpperCase(),
          policy_number: String(policy_number).trim(),
          policy_type: String(policy_type).trim(),
          statement_date: moment(String(statement_date).trim()).toISOString(),
          premium_amount: Number(prem),
          commission: Number(com),
          isValid: true,
        };
        if (affiliate) {
          cr.affiliate = String(affiliate).trim().toUpperCase();
        }
        if (agent_full_name) {
          cr.agent_full_name = String(agent_full_name).trim();
        }
        if (client_name) {
          cr.client_name = String(client_name).trim();
        }
        if (client_dob) {
          cr.client_dob = moment(String(client_dob).trim()).toISOString();
        }
        if (client_ssn) {
          cr.client_ssn = String(client_ssn).trim();
        }
        if (product_name) {
          cr.product_name = String(product_name).trim();
        }
        return cr;
      });

      cleanedRecords.forEach((cr) => {
        const validationResult = validationSchema.validate(cr, { allowUnknown: true });
        if (validationResult.error) {
          setValidationError(validationResult.error);
          cr.isValid = false;
          setAllValid(false);
        }
      });
      setImports(cleanedRecords);

      setSubmitting(true);
      const preTestedRecords = await apiFetch('/eca/test-batch', {
        method: 'POST',
        body: {
          ecas: cleanedRecords,
        },
      });
      let hasErrors = false;
      preTestedRecords.forEach((ptr) => {
        if (ptr.error) {
          ptr.isValid = false;
          hasErrors = true;
        }
      });
      setSubmitting(false);
      if (hasErrors) {
        setForceUpload(true);
      }
      setImports(preTestedRecords);
    } catch (err) {
      setValidationError(err);
    }
  };
  const onChangeFile = (e) => {
    e.persist();
    const { files } = e.target;
    // Forces onChange to get called again if same file is resubmitted
    if (files && files.length) {
      setAllValid(true);
      forEach(files, (f) => {
        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        setFile(f);
        reader.onload = (readerEvent) => handleAddFileContentsToState(readerEvent.target.result);
        reader.readAsText(f);
      });
    }
  };

  const handleSubmitECAs = async () => {
    setSubmissionError('');
    setSubmitting(true);
    for (const imp of imports) {
      delete imp.result;
      delete imp.error;
    }
    const options = {
      method: 'POST',
      body: {
        ecas: imports.map((i) => omit(i, 'ecas')),
      },
    };
    try {
      const batchResult = await apiFetch('/eca/force-batch', options);
      if (batchResult.errors) {
        batchResult.ecas.forEach((eca) => {
          if (eca.error) {
            setSubmissionError(eca.error.message);
            setForceUpload(true);
            eca.isValid = false;
          } else {
            eca.isValid = true;
          }
        });
        setImports(batchResult.ecas);
        setSubmissionError(false);
        return;
      }
      const formData = new FormData();
      formData.append('content_type', file.type);
      formData.append('file', file);
      formData.append('name', file.name);
      apiFetch.post('/comp_upload_files/files', formData)
        .then(() => {
          history.go('/eca');
        })
        .catch((err) => {
          /* eslint-disable-next-line no-console */
          console.error('err', err);
          history.go('/eca');
        });
    } catch (e) {
      setSubmissionError(e.message);
    }
    setSubmitting(false);
    // history.go(0);
  };
  const handleClearPreviousFile = (e) => {
    e.target.value = null;
  };
  const ecaRows = imports.map((i, idx) => {
    return (
      <PreImportRow
        key={i.policy_number + Math.random()}
        {...i}
        number={idx + 1}
      />
    );
  });

  return (
    <>
      <ol>
        <li>
          <a href="/import-eca-template.csv" download>
            <Typography variant="subtitle1">Click Here to download the csv template</Typography>
          </a>
        </li>
        <li><Typography variant="subtitle1">Add your ECAs to the list and upload the file.</Typography></li>
        <li><Typography variant="subtitle1">Review the information is correct in the box below. Import can only proceed if all records are valid. If ECA is colored red it has information that is not formatted correctly or is invalid. Please verify the information and upload the file again.</Typography></li>
        <li><Typography variant="subtitle1">Once the ECAs have been reviewed click the &quot;Submit ECAs&quot; button below.</Typography></li>
      </ol>
      <Button
        color="primary"
        component="label"
      >
        Upload File
        <input
          onClick={handleClearPreviousFile}
          onChange={onChangeFile}
          type="file"
          style={{ display: 'none' }}
        />
      </Button>
      <Typography variant="h6" style={styles.subHeader}>ECAs to Insert</Typography>
      <HiddenContent hidden={!validationError}>
        <span className={styles.errorMsg}>{validationError.message}</span>
      </HiddenContent>
      <ol className={ecaRows.length ? styles.populatedECAList : styles.unpopulatedECAlist}>
        {ecaRows}
      </ol>
      <div>
        <Button
          gold
          disabled={!allValid && !forceUpload}
          onClick={handleSubmitECAs}
        >
          {forceUpload ? 'Force ECAs to upload' : 'Submit ECAs'}
        </Button>
      </div>
      <HiddenContent hidden={!submissionError || submitting}>
        <span className={styles.errorMsg}>{submissionError}</span>
      </HiddenContent>
      <div style={{ marginBottom: '40px' }}/>
    </>
  );
};

export default withStyles(styles)(ImportECA);
